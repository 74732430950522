<div class="sticky-footer">
  <div class="sticky-footer__item" routerLinkActive="sticky-footer__item--active" routerLink="/discover">
    <div class="sticky-footer__item__image-container">
      <mat-icon [svgIcon]="'search'"></mat-icon>
    </div>
  </div>
  <div class="sticky-footer__item" routerLink="/home"
       [class.sticky-footer__item--active]="coursesActive?.isActive || episodesActive?.isActive || homeActive?.isActive">
    <div class="sticky-footer__item__image-container">
      <mat-icon [svgIcon]="'home'"></mat-icon>
    </div>
  </div>
  <div class="sticky-footer__item" routerLinkActive="sticky-footer__item--active" routerLink="/profile">
    <div class="sticky-footer__item__image-container">
      <mat-icon [svgIcon]="'business_woman'"></mat-icon>
    </div>
  </div>
</div>

<div class="sticky-audio-player">
  <hypecast-audio-player-control></hypecast-audio-player-control>
</div>

<a class="display-none" routerLink="/podcasts" routerLinkActive #coursesActive="routerLinkActive"></a>
<a class="display-none" routerLink="/episodes" routerLinkActive #episodesActive="routerLinkActive"></a>
<a class="display-none" routerLink="/home" routerLinkActive #homeActive="routerLinkActive"></a>

<div [class.audio-player-control--visible]="isControlsVisible === true">
  <router-outlet></router-outlet>
</div>
